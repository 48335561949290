.padding-0-10 {
  padding: 0 10px;
}
.padding-0-30 {
  padding: 0 30px;
}
.padding-left-10 {
  padding-left: 10px;
}
.padding-left-30 {
  padding-left: 30px;
}
.margin-0-128 {
  margin: 0 128px;
}
.margin-30-0-0-13 {
  margin: 30px 0 0 13px;
}
.padding-top-10 {
  padding-top: 10px;
}
.padding-top-20 {
  padding-top: 20px;
}
.padding-top-30 {
  padding-top: 30px;
}
.padding-top-56 {
  padding-top: 56px;
}
.padding-bottom-10 {
  padding-bottom: 10px;
}
.padding-bottom-20 {
  padding-bottom: 20px;
}
.padding-bottom-50 {
  padding-bottom: 50px;
}
.padding-top-16 {
  padding-top: 16px;
}
.padding-right-5 {
  padding-right: 5px;
}
.padding-right-40 {
  padding-right: 40px;
}
.margin-right-10 {
  margin-right: 10px;
}
.margin-bottom-5 {
  margin-bottom: 5px;
}
.margin-0-50 {
  margin: 0 50px;
}
.white-background {
  background-color: #ffffff;
}
.white-lighter-background {
  background-color: #fafafa;
}
textarea {
  width: 90%;
  border: 1px solid #d9d9d9;
  padding: 0 10px;
}
.width-100 {
  width: 100%;
}
.text-color-black {
  color: #262626;
}
.text-color-light-gray {
  color: #b0b0b0;
}
.color-black {
  color: rgba(0, 0, 0, 0.85);
}
.text-color-black-0-60 {
  color: rgba(0, 0, 0, 0.6);
}
.filter-dropdown {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
  background: white;
  padding: 5px 10px;
}
.font-size-14 {
  font-size: 14px;
}
.font-size-16 {
  font-size: 16px;
}
.card-wrapper {
  margin: 0 130px;
  width: 70%;
}
.align-center {
  display: flex;
  justify-content: center;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
}
.no-side-bar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.no-side-bar::-webkit-scrollbar {
  display: none;
}
.ant-layout-content {
  padding: 0;
}

.country-phone-code-prefix {
  padding: 7px 12px;
  border-width: 1px 0px 1px 1px;
  border-style: solid;
  border-color: rgb(217, 217, 217);
  border-radius: 2px 0px 0px 2px;
  background: rgb(245, 245, 245);
}

.ant-input-number-handler-wrap { 
  visibility: hidden;
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1280px) {
  .card-wrapper {
    margin: 0 75px;
    width: 100%;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .card-wrapper {
    margin: 0 75px;
    width: 85%;
  }
}

@media only screen and (min-device-width: 1600px) {
  .card-wrapper {
    margin: 0 240px;
    width: 60%;
  }
}
